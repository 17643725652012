
import { reactive, onMounted, computed, ref } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/InvoiceLotsEnums.ts";
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
export default {
  name: 'InvoiceLotsList',
  components : {
    Table,
    DeleteRecord
  },
  setup(){
    onMounted(() => {
      setCurrentPageBreadcrumbs("Lots",[
        {title : "Invoiced Lots List",to : "/lots/invoice-composites"}
      ]);
    });

    const actions = computed(() => {
      return Actions;
    })



    const columns = [
      {name : 'supplier', title : 'Customer', sortable : true, sort : null, searchable : true},
      {name : 'lot', title : 'Lot', sortable : true, sort : null, searchable : true},
      {name : 'quantity', title : 'Quantity', sortable : true, sort : null, searchable : true},
      {name : 'created_at', title : 'Date', sortable : true, sort : 'desc', searchable : true},
      {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ]

    return {
      columns,
      actions
    }

  }
}

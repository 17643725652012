<template>
  <div>

    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">

      <div class="card-header cursor-pointer">

        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">List of Invoiced Lots</h3>
        </div>
        <router-link
          to="/lots/invoice-composites/add"
          class="btn btn-primary align-self-center ms-auto"
          >Invoice Lots</router-link>
      </div>
      <div class="card-body p-7">

        <Table :columns="columns" ref="table" :action="actions.SEARCH_INVOICE_LOTS">


          <template v-slot:actions="slotProps">

            <div>

              <div class="btn-group" role="group">

                <router-link :to="'/lots/invoice-composites/edit/'+slotProps.row.id" class="btn btn-sm btn-light-warning font-weight-bold"><i class="fs-1-3 la la-edit"></i></router-link>

                <DeleteRecord :id="slotProps.row.id" :action="actions.DELETE_INVOICE_LOT" :callback="slotProps.search"/>

              </div>

            </div>

          </template>

        </Table>

      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { reactive, onMounted, computed, ref } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/InvoiceLotsEnums.ts";
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
export default {
  name: 'InvoiceLotsList',
  components : {
    Table,
    DeleteRecord
  },
  setup(){
    onMounted(() => {
      setCurrentPageBreadcrumbs("Lots",[
        {title : "Invoiced Lots List",to : "/lots/invoice-composites"}
      ]);
    });

    const actions = computed(() => {
      return Actions;
    })



    const columns = [
      {name : 'supplier', title : 'Customer', sortable : true, sort : null, searchable : true},
      {name : 'lot', title : 'Lot', sortable : true, sort : null, searchable : true},
      {name : 'quantity', title : 'Quantity', sortable : true, sort : null, searchable : true},
      {name : 'created_at', title : 'Date', sortable : true, sort : 'desc', searchable : true},
      {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ]

    return {
      columns,
      actions
    }

  }
}
</script>
